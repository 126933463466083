import { useEffect, useRef } from "react";
import anime from "animejs";

function LoadingSpinner(props) {
  const animTarget = useRef(0);

  useEffect(() => {
    if (animTarget.current) {
      anime.remove(animTarget.current.querySelectorAll(".neuron"));
      anime({
        targets: animTarget.current.querySelectorAll(".neuron"),
        keyframes: [
          {
            translateX: function () {
              return anime.random(-2, 2);
            },
            translateY: function () {
              return anime.random(-2, 2);
            },
          },
          {
            translateX: function () {
              return anime.random(-2, 2);
            },
            translateY: function () {
              return anime.random(-2, 2);
            },
          },
          { translateX: 0, translateY: 0 },
        ],
        delay: anime.stagger(500),
        loop: true,
        duration: 8000,
        direction: "alternate",
        easing: "easeInOutSine",
      });

      var tl = anime.timeline({
        loop: true,
        duration: 1500,
        direction: "alternate",
        easing: "easeInOutSine",
      });
      tl.add({
        targets: animTarget.current.querySelectorAll(".layer0"),
        keyframes: [
          {
            opacity: () => {
              return anime.random(0.4, 0.6);
            },
            width: 2,
            height: 2,
          },
          {
            opacity: () => {
              return anime.random(0.8, 1);
            },
            width: () => {
              return anime.random(1, 3);
            },
            height: () => {
              return anime.random(1, 3);
            },
          },
        ],
        delay: anime.stagger(100, { grid: [4, 4], from: "first" }),
      });
      tl.add(
        {
          targets: animTarget.current.querySelectorAll(".layer1"),
          keyframes: [
            {
              opacity: () => {
                return anime.random(0.4, 0.6);
              },
              width: 2,
              height: 2,
            },
            {
              opacity: () => {
                return anime.random(0.8, 1);
              },
              width: () => {
                return anime.random(1, 3);
              },
              height: () => {
                return anime.random(1, 3);
              },
            },
          ],
          delay: anime.stagger(100, { grid: [4, 4], from: "first" }),
        },
        "-=100"
      );
      tl.add(
        {
          targets: animTarget.current.querySelectorAll(".layer2"),
          keyframes: [
            {
              opacity: () => {
                return anime.random(0.4, 0.6);
              },
              width: 2,
              height: 2,
            },
            {
              opacity: () => {
                return anime.random(0.8, 1);
              },
              width: () => {
                return anime.random(1, 3);
              },
              height: () => {
                return anime.random(1, 3);
              },
            },
          ],
          delay: anime.stagger(100, { grid: [4, 4], from: "first" }),
        },
        "-=100"
      );
      tl.add(
        {
          targets: animTarget.current.querySelectorAll(".layer3"),
          keyframes: [
            {
              opacity: () => {
                return anime.random(0.4, 0.6);
              },
              width: 2,
              height: 2,
            },
            {
              opacity: () => {
                return anime.random(0.8, 1);
              },
              width: () => {
                return anime.random(1, 3);
              },
              height: () => {
                return anime.random(1, 3);
              },
            },
          ],
          delay: anime.stagger(100, { grid: [4, 4], from: "first" }), // increase delay by 100ms for each elements.
        },
        "-=100"
      );
    }
  }, [animTarget]);

  return (
    <div className={props.className} ref={animTarget}>
      <svg
        className="w-full h-full"
        width={200}
        height={200}
        viewBox="0 0 100 100"
      >
        <rect
          className="neuron layer0"
          fill={props.color ?? "white"}
          y="20"
          x="20"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer0"
          fill={props.color ?? "white"}
          y="40"
          x="20"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer0"
          fill={props.color ?? "white"}
          y="60"
          x="20"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer0"
          fill={props.color ?? "white"}
          y="80"
          x="20"
          width="2"
          height="2"
        />

        <rect
          className="neuron layer1"
          fill={props.color ?? "white"}
          y="20"
          x="40"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer1"
          fill={props.color ?? "white"}
          y="40"
          x="40"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer1"
          fill={props.color ?? "white"}
          y="60"
          x="40"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer1"
          fill={props.color ?? "white"}
          y="80"
          x="40"
          width="2"
          height="2"
        />

        <rect
          className="neuron layer2"
          fill={props.color ?? "white"}
          y="20"
          x="60"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer2"
          fill={props.color ?? "white"}
          y="40"
          x="60"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer2"
          fill={props.color ?? "white"}
          y="60"
          x="60"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer2"
          fill={props.color ?? "white"}
          y="80"
          x="60"
          width="2"
          height="2"
        />

        <rect
          className="neuron layer3"
          fill={props.color ?? "white"}
          y="20"
          x="80"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer3"
          fill={props.color ?? "white"}
          y="40"
          x="80"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer3"
          fill={props.color ?? "white"}
          y="60"
          x="80"
          width="2"
          height="2"
        />
        <rect
          className="neuron layer3"
          fill={props.color ?? "white"}
          y="80"
          x="80"
          width="2"
          height="2"
        />
      </svg>
    </div>
  );
}

export default LoadingSpinner;
