"use client";

import Image from "next/image";
import anime from "animejs/lib/anime.es.js";
import { PuzzleIcon, RadarIcon, BrainCircuitIcon } from "lucide-react";
import { useLayoutEffect, useRef } from "react";
import LoadingSpinner from "@/components/LoadingOverlay/LoadingSpinner/LoadingSpinner";
import Link from "next/link";

export default function Home() {
  useLayoutEffect(() => {
    anime({
      targets: [".letters", ".subText", ".neuralNet"],
      opacity: 1,
      duration: 1700,
      easing: "easeInOutQuad",
    });
  }, []);

  return (
    <main>
      <section className="flex md:flex-row flex-col">
        <div className="md:w-8/12 w-full flex justify-around flex-col">
          <h1 className=" md:mb-16 mb-10 md:mt-12 mt-0 text-wrapper">
            <span className="letters" style={{ opacity: 0 }}>
              Kürze deine Links mit einem Klick
            </span>
          </h1>
          <div className="subText mb-7" style={{ opacity: 0 }}>
            <p>
              Willkommen bei unserem innovativen URL-Shortener! Hier kannst du
              deine langen und unhandlichen Links in kurze, handliche URLs
              verwandeln.
            </p>
            <p>
              Unsere App ist einfach zu bedienen, ohne Werbung und sofort
              einsatzbereit. Keine komplizierten Anmeldungen oder
              Einrichtungsprozesse. Perfekt als Alternative für die bald
              ungültigen goo.gl Links!
            </p>
          </div>
          <Link href={"./shorten"}>
            <button className="btn-primary">
              Jetzt Kostenlos Ausprobieren
            </button>
          </Link>
        </div>
        <div className="neuralNet md:w-4/12 w-full md:static absolute md:block hidden opacity-0">
          <LoadingSpinner color="gray" />
        </div>
      </section>

      <section className="cards flex xl:flex-row flex-col mt-16 mb-24 justify-between">
        <div className="card m-6 flex flex-col justify-start items-center xl:w-4/12 w-11/12 bg-[#062346] rounded-xl p-8 text-white hover:scale-110 transition-all ease-in-out duration-700">
          <RadarIcon className="h-16 w-16 m-6" />
          <h2 className="mb-10 mt-2 text-2xl">Einfachheit</h2>
          <p>
            Kein langwieriger Registrierungsprozess, keine komplizierten
            Einstellungen. Unser URL-Shortener ist so konzipiert, dass du sofort
            loslegen kannst. Einfach den Link eingeben und kürzen – fertig!
          </p>
        </div>
        <div className="card m-6 flex flex-col justify-start items-center xl:w-4/12 w-11/12 bg-[#062346] rounded-xl p-8 text-white hover:scale-110 transition-all ease-in-out duration-700">
          <BrainCircuitIcon className="h-16 w-16 m-6" />
          <h2 className="mb-10 mt-2 text-2xl"> Zuverlässigkeit</h2>
          <p>
            Unsere Plattform bietet eine stabile und zuverlässige Lösung, um
            sicherzustellen, dass deine gekürzten Links immer verfügbar sind.
            Perfekt für geschäftliche und private Zwecke.
          </p>
        </div>
        <div className="card m-6 flex flex-col justify-start items-center xl:w-4/12 w-11/12 bg-[#062346] rounded-xl p-8 text-white hover:scale-110 transition-all ease-in-out duration-700">
          <PuzzleIcon className="h-16 w-16 m-6" />
          <h2 className="mb-10 mt-2 text-2xl">Alternative</h2>
          <p>
            Mit dem bevorstehenden Ende von goo.gl bieten wir eine hervorragende
            Alternative. Unsere Lösung ist nicht nur genauso gut, sondern bietet
            auch zusätzliche Funktionen und bessere Benutzerfreundlichkeit.
          </p>
        </div>
      </section>

      <section className="flex justify-center mb-24">
        <Link href={"./shorten"}>
          <button className="btn-primary">Jetzt Kostenlos Ausprobieren</button>
        </Link>
      </section>
    </main>
  );
}
